import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { RouterModule } from '@angular/router';
import { InfluentialFactorsComponent } from './influential-factors';
import { EnergyPerformanceComponent } from './energy-performance.component';
import { LoadingSpinnerModule } from 'src/modules/app/components/loading-spinner/loading-spinner.module';
import { UiGraphModule } from 'src/modules/app/components/ui-graph/ui-graph.module';
import { CardErrorModule } from 'src/modules/app/components/card-error/card-error.module';

@NgModule({
  declarations: [EnergyPerformanceComponent,InfluentialFactorsComponent],
  imports: [
    CommonModule,
    LoadingSpinnerModule,
    UiGraphModule,
    CardErrorModule,
    RouterModule,
  ],
  exports: [EnergyPerformanceComponent,InfluentialFactorsComponent]
})
export class EnergyPerformanceModule { }
